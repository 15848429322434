<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb />
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col col-12 col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <button
                      v-b-modal.modal-brand-create
                      v-if="$can('manage', 'external-options')"
                      class="btn btn-primary"
                  >
                    <span class="text-nowrap">Добавить справочник</span>
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        @change="getOptions" class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-2">
                  <label>ID справочника</label>
                  <input type="text" class="form-control" v-model="form.id">
                </div>
                <div class="col-2">
                  <label>External ID категории</label>
                  <input type="text" class="form-control" v-model="form.external_id">
                </div>
                <div class="col-3 align-self-end">
                  <button class="btn btn-success" @click="addCategory">Добавить категорию</button>
                </div>
              </div>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ options.from }} по {{ options.to }} из {{ options.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="options.total"
                      :per-page="options.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>External Id</th>
                  <th>Название</th>
                  <th>Маркетплейс</th>
                  <th>Внешние Категории</th>
                  <th>Привязанные Опции</th>
                  <th>Parent external Id</th>
                  <th colspan="2"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="option in options.data" :key="option.id">
                  <td>{{ option.id }}</td>
                  <td>{{ option.external_id }}</td>
                  <td>{{ option.name }}</td>
                  <td>{{ option.external_service.name }}</td>
                  <td>{{ option.external_category_ids || '-' }}</td>
                  <td>{{ option.external_dictionaries_count || '-' }}</td>
                  <td>{{ option.parent_external_id }}</td>
                  <td>
                    <router-link :to="{name: 'external-option-update', params: { id: option.id }}">
                      <feather-icon icon="EditIcon"/>
                    </router-link>
                  </td>
                  <td>
                      <feather-icon icon="TrashIcon" class="text-danger font-medium-1 cursor-pointer" @click="deleteOption(option.id)"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ options.from }} по {{ options.to }} из {{ options.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="options.total"
                      :per-page="options.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <ExternalOptionCreateModal @update="getOptions"/>
  </div>
</template>

<script>
import { BFormInput, BPagination } from 'bootstrap-vue';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import ExternalOptionCreateModal from '@/views/externalOptions/ExternalOptionCreateModal.vue';

export default {
  name: 'ExternalOptionsList',
  components: {
    BFormInput,
    BPagination,
    AppBreadcrumb,
    ExternalOptionCreateModal,
  },
  data() {
    return {
      currentPage: 1,
      breadcrumbs: [],
      selectedBrand: null,
      form: {
        id: null,
        external_id: null,
      },
      options: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      searchQuery: null,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.options.current_page = newValue;
      await this.getOptions();
    },
  },
  async mounted() {
    await this.getOptions();
  },
  methods: {
    async getOptions() {
      this.options = (await this.$api.externalOptions.list({
        ...(this.options.current_page > 1 && { page: this.options.current_page }),
        limit: 25,
        ...(this.searchQuery && { search: this.searchQuery }),
      })).data;
    },
    async deleteOption(optionId) {
      try {
        await this.$api.externalOptions.delete(optionId);
        this.$toast.success('Опция удалена');
        await this.getOptions();
      } catch (e) {
        this.$toast.error('Ошибка при добавлении категории');
      }
    },
    addCategory() {
      if (!this.form.id || !this.form.external_id) {
        this.$toast.error('Заполните все поля');
        return;
      }

      try {
        this.$api.externalOptions.addCategory(this.form.id, {
          external_id: this.form.external_id,
        });
        this.$toast.success('Категория добавлена');
        this.getOptions();
      } catch (e) {
        this.$toast.error('Ошибка при добавлении категории');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
