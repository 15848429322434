<template>
  <b-modal
      id="modal-brand-create"
      title="Создание нового бренда"
      ok-title="Сохранить"
      @ok="save"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="form-group">
      <label class="title">Название *</label>
      <input type="text" class="form-control" v-model="form.name">
    </div>
    <div class="form-group">
      <label>External Id</label>
      <input v-model="form.external_id" class="form-control">
    </div>
    <div class="form-group">
      <label>Parent External Id</label>
      <input v-model="form.parent_external_id" class="form-control">
    </div>
    <div class="form-group">
      <div class="form-group">
        <label for="brand" class="d-block">Маркетплейс</label>
        <div>
          <v-select
              v-model="form.external_service_id"
              :clearable="false"
              :options="[3,6]"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'ExternalOptionCreateModal',
  components: {
    BModal, vSelect,
  },
  data() {
    return {
      form: {
        name: null,
        external_id: null,
        parent_external_id: null,
        external_service_id: 3,
      },
    };
  },
  methods: {
    async save() {
      if (!this.form.name || !this.form.external_id || !this.form.parent_external_id) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      try {
        await this.$api.externalOptions.create(this.form);
        this.$bvToast.toast('Справочник успешно создан', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$emit('update');
      } catch (e) {
        this.$bvToast.toast('Ошибка при создании бренда', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>
